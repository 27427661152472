import React from 'react';
import web_img from '../images/web.svg';
import app_img from '../images/app.svg';
import custom_software_development from '../images/custom_software_development.svg';
import hosting from '../images/hosting.svg';
import consultation from '../images/consultation.svg';
import digital_marketing from '../images/digital-marketing.svg';
const Services = () => {
    return (
        <section id="services" className="section">
            <div className="container" data-aos="fade-up">
                <div className="section-header mb-4 text-center">
                    <h3 className="contact-title">Our Services</h3>
                    {/* <!-- <p>We are deeply committed to the growth and success of our clients.</p> --> */}
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="feature text-center" id="web-development">
                            <img alt='' src={web_img} />
                            <h2>Web Development</h2>
                            <p>We create responsive and scalable web applications that drive business growth and enhance
                                user
                                engagement.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="feature text-center" id="mobile-app-development">
                            <img alt='' src={app_img} />
                            <h2>Mobile App Development</h2>
                            <p>Our team designs and develops innovative mobile apps that provide a seamless user experience
                                across all devices.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="feature text-center" id="custom-software-development">
                            <img alt='' src={custom_software_development} />
                            <h2>Custom Software Development</h2>
                            <p>We create bespoke software solutions that are tailored to your specific business
                                requirements. From concept to
                                deployment, we ensure that every aspect of the software aligns with your objectives.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="feature text-center" id="cloud-solutions">
                            <img alt='' src={hosting} />
                            <h2>Cloud Solutions</h2>
                            <p>Anmatics Technologies offers robust cloud computing services that help businesses leverage
                                the power of the cloud for
                                increased efficiency and flexibility. Our cloud solutions are designed to support your
                                growth and innovation.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="feature text-center" id="it-consulting">
                            <img alt='' src={consultation} />
                            <h2>IT Consulting</h2>
                            <p>Our expert consultants provide strategic advice and insights to help you make informed
                                technology decisions. We work
                                closely with you to understand your business goals and recommend solutions that drive
                                success.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="feature text-center" id="digital-marketing">
                            <img alt='' src={digital_marketing} />
                            <h2>Digital Marketing</h2>
                            <p>We offer comprehensive digital marketing services to help businesses increase their online
                                visibility and reach their target audience effectively.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
