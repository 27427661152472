// src/components/NotFound.js
import React from 'react';

const Blogs = () => (
    <div className="container vh-90 d-flex justify-content-center align-items-center mt-5">
        <div className="text-center">
            <h4 className="display-6 fw-bold">Welcome to Anmatics Blogs</h4>
            <p className="lead">Coming soon....</p>
            {/* <p className="text-muted">Sorry for your trouble.</p> */}
            <a href="/" className="btn btn-primary">Go Home</a>
        </div>
    </div>
);

export default Blogs;
