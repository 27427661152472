import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
const Footer = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-4 mb-4">
                        <div className="card border-0 shadow-lg text-center rounded-3">
                            <div className="card-body p-4 rounded-3">
                                <h3 className="card-title at-primary font-weight-bold mb-3">
                                    Anmatics<sup>&#8482;</sup> Technologies</h3>
                                <div className="card-text at-primary">
                                    207, Second floor, City Mall,
                                    Nr. Bhaktinagar Bypass,
                                    Sanala Road, Morbi,
                                    Gujarat, 363641, IN
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4 text-white">
                        <h5>Quick Links</h5>
                        <div className="footer-links">
                            <ul>
                                <li><a href="/#home">Home</a></li>
                                <li><a href="/#about">About</a></li>
                                <li><a href="/#services">Services</a></li>
                                <li><a href="/#contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4 text-white">
                        <h5>Follow Us</h5>
                        <div className="social-icons">
                            {/* <a href="#" className="social-icon"><i className="fab fa-facebook-f fa-2x"></i></a> */}
                            <a href="https://www.facebook.com/anmatics-technologies/" className="social-icon"><FontAwesomeIcon className='social-icon' icon={faFacebookF} size="2x" /></a>
                            <a href="https://x.com/anmatics-technologies/" className="social-icon"><FontAwesomeIcon className='social-icon' icon={faXTwitter} size="2x" /></a>
                            <a href="https://www.linkedin.com/company/anmatics-technologies/" className="social-icon"><FontAwesomeIcon className='social-icon' icon={faLinkedinIn} size="2x" /></a>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12 text-center text-white">
                        <p>&copy; {new Date().getFullYear()}{"  "} Anmatics<sup className="sup-bottom">&#8482;</sup> Technologies. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
