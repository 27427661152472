// src/components/NotFound.js
import React from 'react';

const NotFound = () => (
    <div className="container vh-90 d-flex justify-content-center align-items-center">
        <div className="text-center">
            <h1 className="display-1 fw-bold">404</h1>
            <p className="lead">Page Not Found</p>
            <p className="text-muted">Sorry, the page you are looking for does not exist.</p>
            <a href="/" className="btn btn-primary">Go Home</a>
        </div>
    </div>
);

export default NotFound;
