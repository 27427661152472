import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
const Contact = () => {
    return (
        <section id="contact" className="contact pb-5">
            <div className="container" data-aos="fade-up">
                <div className="section-header text-center">
                    <h3 className="contact-title">Contact Us</h3>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="card border-0 shadow-lg text-center rounded-3">
                            <div className="card-body p-4 rounded-3">
                                <h5 className="card-title font-weight-bold mb-3">Our Address</h5>
                                <div className="row">
                                    <div className="col-auto">
                                        {/* <i className="fas fa-map-marker-alt at-primary me-2 animate-rocket"></i> */}
                                        <FontAwesomeIcon className='at-primary animate-rocket' icon={faMapMarkerAlt}></FontAwesomeIcon>
                                        <strong>Address:</strong>
                                    </div>
                                    <div className="col">
                                        <ul className="list-unstyled text-start">
                                            <li className="mb-2">
                                                <span>207, Second floor, City Mall, Nr. Bhaktinagar Bypass,
                                                    Sanala Road, Morbi,
                                                    Gujarat, 363641,
                                                    IN</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card border-0 shadow-lg text-center rounded-3">
                            <div className="card-body p-4 rounded-3">
                                <h5 className="card-title font-weight-bold mb-3">Contact Information</h5>
                                <ul className="list-unstyled text-start">
                                    <li className="mb-2">
                                        <FontAwesomeIcon className='at-primary' icon={faEnvelope}></FontAwesomeIcon>
                                        <strong className="d-inline-block">Email:</strong>
                                        <a href="mailto:info@anmatics.com"
                                            className="text-decoration-none at-primary">info@anmatics.com</a>
                                    </li>
                                    <li className="mb-2">
                                        <FontAwesomeIcon className='at-primary' icon={faPhone}></FontAwesomeIcon>
                                        <strong className="d-inline-block" >Phone:</strong>
                                        <a href="tel:+919503534324" className="text-decoration-none at-primary">+91
                                            9503534324</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Contact Us</h5>
                                <form id="contactForm">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="name" placeholder="Your Name" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" id="email" placeholder="Your Email"
                                            required />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" id="message" rows="5" placeholder="Your Message"
                                            required></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Send Message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
