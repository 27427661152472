import React from 'react';
import about_img from '../images/about-img.svg';
import about_1_img from '../images/about-extra-1.svg';
import about_2_img from '../images/about-extra-2.svg';
const About = () => {
    return (
        <section id="about">
            <div className="container" data-aos="fade-up">
                <div className="section-header mb-4 text-center">
                    <h3 className="contact-title">About Us</h3>
                    {/* <!-- <p>Anmatics Technologies is one of the fastest growing company in IT sector of this era.</p> --> */}
                </div>
                <div className="row about-container">
                    <div className="col-lg-6 content order-lg-1 order-2">
                        <p>Anmatics Technologies is a leading software development company dedicated to delivering
                            innovative solutions that help
                            businesses thrive in the digital age. We specialize in web development, mobile app development,
                            custom software
                            solutions, cloud computing, IT consulting, and digital marketing.</p>
                        <p></p>
                        <p></p>
                        <h5>Our Approach</h5>
                        <p>At Anmatics Technologies, we follow a client-centric approach that puts your needs at the
                            forefront of everything we do.
                            Our process is collaborative, ensuring that you are involved at every stage of the project. We
                            start by understanding
                            your vision and objectives, then move on to detailed planning and design. Our agile development
                            methodology allows us to
                            deliver high-quality software solutions quickly and efficiently.</p>
                        <p></p>
                        <p>We believe in transparency and open communication, keeping you informed and engaged throughout
                            the development process.
                            Our commitment to quality ensures that the final product is thoroughly tested and optimized for
                            performance.</p>
                    </div>
                    <div className="col-lg-6 background order-lg-2" data-aos="zoom-in">
                        <img src={about_img} className="img-fluid" alt="" />
                    </div>
                </div>
                <div className="row about-extra">
                    <div className="col-lg-6" data-aos="fade-right">
                        <img src={about_1_img}
                            className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-5 pt-lg-0" data-aos="fade-left">
                        <h4>Who are we</h4>
                        <p>Anmatics Technologies is a team of passionate software developers, designers, and project
                            managers who are committed to
                            excellence. Our diverse expertise spans various industries, allowing us to provide tailored
                            solutions that address
                            unique challenges and opportunities. Whether you're a startup looking to launch your first app
                            or an established
                            enterprise seeking to modernise your existing systems, we have the skills and experience to help
                            you achieve your goals.
                        </p>
                    </div>
                </div>
                <div className="row about-extra">
                    <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                        <img src={about_2_img}
                            className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-right">
                        <h4>Why Choose Anmatics Technologies ?</h4>
                        <p><b>Expertise and Experience:</b> Our team consists of seasoned professionals with a proven track
                            record in software
                            development. We bring a wealth of knowledge and experience to every project.</p>
                        <p>
                        </p>
                        <p><b>Innovative Solutions:</b> We stay ahead of the curve by adopting the latest technologies and
                            industry best practices. Our innovative approach
                            ensures that your software solution is future-proof and scalable.</p>
                        <p>
                        </p>
                        <p><b>Customer Satisfaction:</b> Your satisfaction is our top priority. We strive to deliver
                            solutions that not only meet but exceed your expectations,
                            building long-term relationships based on trust and success.</p>
                        <p>
                        </p>
                        <p><b>Cost-Effective Services:</b> We offer competitive pricing without compromising on quality. Our
                            solutions are designed to provide maximum value for
                            your investment.
                        </p>
                        {/* <!-- <ul>
                            <li>Team of creative crackerjacks and innovative developers.</li>
                            <li>Latest technologies always on our hands and systems.</li>
                            <li>We hate delays, so just like the Japanese bullet trains we will never make you wait.</li>
                            <li>We are always available to help and support you.</li>
                            <li>100% Quality Assurance</li>
                        </ul> --> */}
                        <p></p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
