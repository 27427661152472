import React from 'react';
import home_img from '../images/web-dev.svg';
const Home = () => {
    return (
        <section className="home pb-5" id="home">
            <div className="container overflow-hidden mt-8 mt-lg-4 p-2 p-md-12" data-aos="zoom-in">
                <div className="row py-8 text-center text-lg-start">
                    <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="zoom-in" data-aos-delay="200">
                        <div id="textCarousel" className="carousel slide pb-5" data-bs-ride="carousel">
                            <div className="carousel-inner mt-5">
                                <div className="carousel-item active">
                                    <h1 className="mb-5 display-6 font-bold at-primary">We build digital solutions to help businesses scale.</h1>
                                    <div className="h5 font-semibold text-muted mb-5">We are a team of highly motivated and skilled developers dedicated to delivering only the best software.</div>
                                </div>
                                <div className="carousel-item">
                                    <h1 className="mb-5 display-6 font-bold at-primary">Transforming ideas into reality.</h1>
                                    <div className="h5 font-semibold text-muted mb-5">Our expert developers bring innovative solutions to the table, ensuring your business stands out.</div>
                                </div>
                                <div className="carousel-item">
                                    <h1 className="mb-5 display-6 font-bold at-primary">Your success is our mission.</h1>
                                    <div className="h5 font-semibold text-muted mb-5">We work closely with you to understand your goals and deliver software that drives results.</div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 mb-md-8 align-self-end">
                            <a href="#about" className="btn btn-primary btn-lg shadow-lg rounded-2xl">
                                Learn more
                                <svg className="bi bi-arrow-right ms-1" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-lg-end" data-aos="fade-up" data-aos-delay="700">
                        <img alt="card img" className="rounded float-end w-100" src={home_img} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
