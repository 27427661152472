import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Services from './components/Services';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import NotFound from './components/NotFound';
import About from './components/About';
import Contact from './components/Contact';
import Blogs from './components/Blogs';

const App = () => {
  return (
    <div>
      <Header />
      <main id="main">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
