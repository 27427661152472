import React, { useRef } from 'react';
// import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import anmatics_logo from '../images/anmatics_logo.svg'
const Header = () => {
    // Expand nav menu on mobile view and when click on nav link hide the menu
    const navButton = useRef(null);
    const linksContainerRef = useRef(null);

    function collapseNav() {
        navButton.current.classList.add("collapsed");
        linksContainerRef.current.classList.remove("show");
    }
    return (
        <header className="fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <HashLink className="navbar-brand logo at-primary name-title" to="/#home">
                        <img src={anmatics_logo} alt="Anmatics Technologies" />
                        <span>Anmatics</span><sup>&#8482;</sup>
                    </HashLink>
                    <button ref={navButton} className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav" ref={linksContainerRef}>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <HashLink onClick={collapseNav} className="nav-link active" aria-current="page" to="/#home">Home</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink onClick={collapseNav} className="nav-link" to="/#about" smooth>About</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink onClick={collapseNav} className="nav-link" to="/#services" smooth>Services</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink onClick={collapseNav} className="nav-link" to="/#contact" smooth>Contact</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink onClick={collapseNav} className="nav-link" to="/blogs" smooth>Blogs</HashLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
