import Home from '../components/Home';
import About from '../components/About';
import Services from '../components/Services';
import Contact from '../components/Contact';

const HomePage = () => {
    return (
        <div>
            <Home />
            <About />
            <Services />
            <Contact />
        </div>
    )
}

export default HomePage;